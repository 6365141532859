import React from "react"
import { css } from "@emotion/core"

const SectionHeader = ({ children, align='center' }) => {

  return (
    <h3
      css={css`
        transform:rotate(-1deg);
        text-align: ${align};
        font-family: Special Elite, serif;
        text-transform: uppercase;
        margin:20px 0;
        
        &:first-of-type {
          margin-top:0;
        }
        
        span {
          color:white;
          background-color: #101010;
          padding: 10px 10px 1px;
          margin-top:10px;
          display:inline;
          box-decoration-break: clone;
        }
        
        @media screen and (min-width: 480px) {
          font-size: 28px;
        }
        @media screen and (min-width: 740px) {
          font-size: 36px;
        }
        @media screen and (min-width: 960px) {
          font-size: 48px;
        }
        font-size: 24px;
`
      }
    >
      <span className={'fx-shadow-hard'}>{children}</span>
    </h3>
  )
}

export default SectionHeader
