import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Img from 'gatsby-image'
import { css } from "@emotion/core"
import Color from 'color'

// TODO: query for anthologies (or just query ParanoidPamphlets / PAH directly)

const Header = ({ location, children, color='#cbcbcb' }) => (
	<StaticQuery
		query={graphql`
query HeadingQuery {
      
   logo: file(relativePath: { eq: "paranoid-american-logo.png" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      # Makes it trivial to update as your page's design changes.
      fluid(maxWidth:64) {
        ...GatsbyImageSharpFluid
      }
    }
  }
      
  publishedComics: allMarkdownRemark(filter: {frontmatter: {published: {ne: false}, type: {eq: "comic"}, anthology: {ne: true}}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        id
        fields { slug }
        frontmatter {
            title
            order
        }
      }
    }
  }
  
  unpublishedComics: allMarkdownRemark(filter: {frontmatter: {published: {eq: false}, type: {eq: "comic"}}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        id
        fields { slug }
        frontmatter {
            title
            order
        }
      }
    }
  }
  
  publishedParanoidAmericanHistory: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic-issue"}, comic: {eq: "Paranoid American History"}}}) {
    edges {
      node {
        id
        fields { slug }
        frontmatter {
            title
        }
      }
    }
  }
  
  publishedParanoidPamphlets: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic-issue"}, comic: {eq: "Paranoid Pamphlets"}}}) {
    edges {
      node {
        id
        fields { slug }
        frontmatter {
            title
        }
      }
    }
  }
  
}
    `}
		render={data => (

			<header css={css`
			background: #101010;
			width: 100%;
			
			font-family: Special Elite, serif;
			`}>

				<div css={css`
						margin: 0 auto;
						max-width: 1200px;
						min-width:360px;
						
						.navbar-brand {
							font-size: 2em;
						}
						
						.navbar-nav {
							> * {
								margin: 0 8px;
							}
							
            font-size: 14px;
            
            > * {
							margin: 0 2px;
						}
			          @media screen and (min-width: 740px) {
			            font-size: 16px;
                  
                  > * {
										margin: 0 2px;
									}
			          }
							@media (min-width: 1000px) {
                  font-size: 20px;
                  
                  > * {
										margin: 0 4px;
									}
	            }
							
						}
						
						.nav-link { 
						 font-size: 1.2em;
						}
				
					.dropdown-menu {
					font-family: Bitter, sans-serif;
					background: #202020;
					box-shadow: -1px 7px 10px 2px rgba(0,0,0,0.75);
					
					font-size:16px;
					@media screen and (min-width: 960px) {
						font-size:18px;
          }
					
					a {
						color:${Color(color).hex()};
					}
				
					.dropdown-divider {
						border-color: ${color};
					}
					
					.dropdown-header {
						color: ${color};
					}
				
					.dropdown-item {
						&:focus, &:hover, &.active {
							background: ${color};
							color: ${Color(color).darken(0.5).desaturate(0.25).hex()};
						}
					}
				}
				`}>
					<Navbar variant="dark" collapseOnSelect expand="md">
						<Navbar.Brand
							href="/"
							as={Link} to={"/"}
							css={css`
									display: flex;
									flex-direction: row;
									flex-wrap: nowrap;
							`} >

							<Img
								alt={'Paranoid American Logo'}
								fluid={data.logo.childImageSharp.fluid}
								css={css`
									@media screen and (min-width: 480px) {
				            margin-right: 20px;
										min-width:36px;
										min-height:36px;
				          }
				          @media screen and (min-width: 740px) {
				            margin-right: 16px;
										min-width:44px;
										min-height:44px;
				          }
				          @media screen and (min-width: 1000px) {
				            margin-right: 20px;
										min-width:64px;
										min-height:64px;
				          }

`}
							/>

								<h1 className={'h-100 mt-auto my-auto'} css={css`
								font-family: Special Elite, serif;
								color: #ffffff;
								line-height: 0.8em;
								transition: font-size 0.2s;
								font-size: 24px;
								position:relative;
		            top:2px;
								@media screen and (min-width: 840px) {
			            font-size: 32px;
			            position:relative;
			            top:4px;
			          }
			          @media screen and (min-width: 1200px) {
			            font-size: 40px;
			            position:relative;
			            top:4px;
			          }
							`}>Paranoid American</h1>
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
						<Navbar.Collapse id="basic-navbar-nav">
							<Nav
								className={'ml-auto'}
								css={css`
									@media screen and (min-width:740px) {
                    position: relative;
									  top:3px;
                  }
                  @media screen and (min-width:1000px) {
                    position: relative;
									  top:5px;
                  }
								`}>

								<NavDropdown
									className={'h-100'}
									title="Comics"
									id="basic-nav-dropdown"
									active={
										['/time-samplers/',
											'/nasa/',
											'/fiction-faction/',
											'/black-mass-pizza/',
											'/tellamind/',
											'/lees-demons/',
											'/secret-mystery-school/',
											'/power-to-the-sheeple/',
											'/connect-the-dots/',
											'/paranoid-pamphlets/',
											'/paranoid-american-history/',
											'/comics/',
											'/comics'].includes(
												location &&
													location.pathname.match(/^\/[^/]*\//)
													? location.pathname.match(/^\/[^/]*\//)[0]
													: location && location.pathname)}>

									{data.publishedComics.edges.map(({ node }) => {
										return (
											<NavDropdown.Item
												key={`published-${node.fields.slug}`}
												href={node.fields.slug}
												active={location && node.fields.slug === location.pathname}
												as={Link}
												to={node.fields.slug}>{node.frontmatter.title}</NavDropdown.Item>
										)
									})}
									<NavDropdown.Divider />
									<NavDropdown.Header>Paranoid Pamphlets</NavDropdown.Header>
									{data.publishedParanoidPamphlets.edges.map(({ node }) => {
										return (
											<NavDropdown.Item
												key={`pap-${node.fields.slug}`}
												href={node.fields.slug}
												active={location && node.fields.slug === location.pathname}
												as={Link}
												to={node.fields.slug}>{node.frontmatter.title}</NavDropdown.Item>
										)
									})}
									<NavDropdown.Divider />
									<NavDropdown.Header>Paranoid American History</NavDropdown.Header>
									{data.publishedParanoidAmericanHistory.edges.map(({ node }) => {
										return (
											<NavDropdown.Item
												key={`pah-${node.fields.slug}`}
												href={node.fields.slug}
												active={location && node.fields.slug === location.pathname}
												as={Link}
												to={node.fields.slug}>{node.frontmatter.title}</NavDropdown.Item>
										)
									})}
									<NavDropdown.Divider />
									<NavDropdown.Header>Coming Soon (Unpublished Comics)</NavDropdown.Header>
									{data.unpublishedComics.edges.map(({ node }) => {
										return (
											<NavDropdown.Item
												key={`unpublished-${node.fields.slug}`}
												href={node.fields.slug}
												active={location && node.fields.slug === location.pathname}
												as={Link}
												to={node.fields.slug}>{node.frontmatter.title}</NavDropdown.Item>
										)
									})}
								</NavDropdown>
								<Nav.Link
									className={'h-100'}
									href="/research"
									as={Link}
									to={"/research"}
									active={
										['/research/', '/research'].includes(
											location &&
											location.pathname.match(/^\/[^/]*\//)
												? location.pathname.match(/^\/[^/]*\//)[0]
												: location && location.pathname)}
								>Research</Nav.Link>
								<Nav.Link
									className={'h-100'}
									href="/blog"
									as={Link}
									to={"/blog"}
									active={
										['/blog/', '/blog'].includes(
											location &&
											location.pathname.match(/^\/[^/]*\//)
												? location.pathname.match(/^\/[^/]*\//)[0]
												: location && location.pathname)}
								>Blog</Nav.Link>
								<Nav.Link className={'h-100'} href="/about" as={Link} to={"/about"} active={location && location.pathname === '/about'}>About</Nav.Link>
								<Nav.Link
                  className={'h-100'}
                  href="/shop"
                  as={Link}
                  to={"/shop"}
                  active={
	                  ['/shop/', '/shop'].includes(
		                  location &&
		                  location.pathname.match(/^\/[^/]*\//)
			                  ? location.pathname.match(/^\/[^/]*\//)[0]
			                  : location && location.pathname)}
								>Shop</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>

				</div>
			</header>
		)}
	/>
)

/*
Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: `Paranoid American`,
}
*/

export default Header