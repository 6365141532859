import React from "react"
import { css } from "@emotion/core"

const PageHeader = ({ children }) => {

  return (
    <h1
      className={'fx-3d'}
      css={css`

        a {
          color: white;
          &:hover {
            text-decoration: none;
          }
        }

        text-align: center;
        font-family: Work Sans, sans-serif;
        font-weight: 800;
        line-height: 0.8em;
        color: white;
        margin-top: 20px;
        margin-bottom: 24px;
        text-transform: uppercase;        
        @media screen and (min-width: 480px) {
          font-size: 32px;
          margin-bottom:30px;
        }
        @media screen and (min-width: 740px) {
          font-size: 48px;
        }
        @media screen and (min-width: 960px) {
          font-size: 64px;
        }
        font-size: 32px;
`
      }
    >
      <span>{children}</span>
    </h1>
  )
}

export default PageHeader
