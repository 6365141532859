import _ from "lodash"

/*

  USE CASES
  ==================
  - WebSite
    - WebPage

  - Comic Series
    - Comic Issue
      - Comic Page
 */

const contextProp = {
  "@context": "http://schema.org",
}

const genreProp = {
  genre: ["http://vocab.getty.edu/aat/300410243", // conspiracies
    "http://vocab.getty.edu/aat/300203177", // - comic books
    "http://vocab.getty.edu/aat/300265568", //- graphic novels
    "http://vocab.getty.edu/aat/300055919", // - sci fi
    "http://vocab.getty.edu/aat/300256621", //1 - political art
  ],
}

const inLanguageProp = { inLanguage: "en-US" }
const contentRatingProp = { contentRating: "NR" }
const keywordsProp = {
  keywords: [
    "Social Networking",
    "Social Media",
    "News",
    "Web",
    "Technology",
    "Web 2.0",
    "Tech",
    "Information",
    "Blog",
    "Facebook",
    "YouTube",
    "Google",
    "Top",
  ],
}

const ImageObject = ({url, caption, thumbnail}) => {
  return {
    ...contextProp,
    "@type": "ImageObject",
    "@id": url,
    url,
    caption,
    representativeOfPage: true, // is image representative of page content?
    thumbnail
  }
}

const Organization = {
  ...contextProp,
  "@type": "Organization",
  name: "Paranoid American",
  alternateName: "Paranoid American",
  url: "https://paranoidamerican.com",
  legalName: "Paranoid American Inc.",
  logo: "https://paranoidamerican.com/logo.png",
  description: "Paranoid American is a small independent publisher focused on Comics & Graphic Novels about Conspiracy Theories and the Occult.",
  contactPoint: [
    {
      "@type": "ContactPoint",
      contactType: "customer service",
      email: "contact@paranoidamerican.com",
      availableLanguage: [
        "English",
      ],
    },
  ],
  sameAs: [
    "https://www.instagram.com/paranoidamerican/",
    "https://twitter.com/paranoidamerica",
    "https://www.facebook.com/ParanoidAmerican/",
    "https://www.comixology.com/Paranoid-American-Inc/comics-publisher/467-0",
    "https://www.drivethrucomics.com/browse/pub/4936/Paranoid-American-Inc",
    "https://indyplanet.com/time-samplers-1",
    "https://paranoidamerican.etsy.com/",
    "https://paranoidamerican.tumblr.com/",
  ],
  foundingDate: "2012",

}

const creativeWorkProps = {
  abstract: "",
  alternativeHeadline: "",
  associatedMedia: "",
  author: {}, // Organization
  character: {}, // fictional person object
  contentRating: {}, // Rating
  copyrightHolder: Organization, // Organization
  copyrightYear: new Date().getYear(), // Number
  creativeWorkStatus: "Published", // Draft | Published
  dateCreated: null, // Date
  datePublished: null, // Date
  editor: null, // Person
  ...inLanguageProp,
  isAccessibleForFree: true,
  ...genreProp,
  publisher: Organization,
}

const thingProps = {
  additionalType: "",
  alternateName: "",
  description: "",
  name: "",
  url: "",
}

const webPageProps = {
  breadcrumb: "",
  mainContentOfPage: null,
  primaryImageOfPage: null,
  ...creativeWorkProps,
}

const creativeWorkCollection = {
  collectionSize: 7,
}

const Person = ({ name = "John Doe", alternateName, sameAs, description, image }) => {
  return {
    ...contextProp,
    "@type": "Person",
    name,
    alternateName,
    sameAs,
    description,
    image,
  }
}

// comic persons
const comicPersons = {
  artist: Person({ name: "John Doe" }),
  colorist: Person({ name: "John Doe" }),
  inker: Person({ name: "John Doe" }),
  letterer: Person({ name: "John Doe" }),
  penciler: Person({ name: "John Doe" }),
}

const visualArtworkProps = {
  artMedium: "digital",
  //...comicPersons
}

const publicationIssueProps = {
  issueNumber: 1,
  pageEnd: 10,
  pageStart: 1,
}

// https://schema.org/WebSite
const WebSite = {
  ...contextProp,
  "@type": "WebSite",
  "@id": "https://paranoidamerican.com/#website",
  ...inLanguageProp,
  ...contentRatingProp,
  url: "https://paranoidamerican.com",
  name: "Paranoid American",
  alternateName: "paranoidamerican",
  headline: "Paranoid American",
  image: "https://paranoidamerican.com/logo.png",
  description: "Paranoid American is a small independent publisher focused on Comics & Graphic Novels about Conspiracy Theories and the Occult.",
  disambiguatingDescription: "Official website of Paranoid American",
}

const WebPageElement = ({ cssSelector }) => {
  return {
    "@type": "WebPageElement",
    ...contextProp,
    cssSelector,
    //...creativeWorkProps,
  }
}

// https://schema.org/WebPage
const WebPage = {
  ...contextProp,
  "@type": "Webpage",
  mainContentOfPage: WebPageElement({ cssSelector: "body" }),
}

const ItemPage = {
  ...WebPage,
  "@type": "ItemPage",
}

const AboutPage = {
  ...WebPage,
  "@type": "AboutPage",
}

const ContactPage = {
  ...WebPage,
  "@type": "ContactPage",
}

const Book = {
  "@type": "Book",
  ...contextProp,
  ...creativeWorkProps,
  abridged: false,
  bookEdition: "",
  bookFormat: "GraphicNovel", // GraphicNovel | Hardcover | Paperback | EBook
  isbn: "",
  numberOfPages: 10,
}

const Chapter = {
  "@type": "Chapter",
  ...contextProp,
  ...creativeWorkProps,
  pageEnd: 10,
  pageStart: 1,
}

// ComicStory
// The term "story" is any indivisible, re-printable unit of a comic, including the interior stories, covers, and
// backmatter. Most comics have at least two stories: a cover (ComicCoverArt) and an interior story.
const ComicStory = [{
  "@type": "ComicStory",
  ...contextProp,
  ...comicPersons,
  ...creativeWorkProps,
  ...visualArtworkProps,
}]

// This is already a subset of ComicStory, so use this instead (if applicable)
const ComicCoverArt = [{
  "@type": "ComicCoverArt",
  ...contextProp,
  ...comicPersons,
  ...visualArtworkProps,
}]

const BookSeries = ({ issn, creativeWorkProps, thingProps }) => {
  return {
    ...contextProp,
    "@type": "BookSeries",
    issn, //  repeat this property to identify different formats
    ...creativeWorkProps,
    ...thingProps,
  }
}

const ComicIssue = ({ publicationIssueProps, comicPersons, creativeWorkProps, thingProps }) => {
  return {
    ...contextProp,
    "@type": "ComicIssue",
    ...comicPersons,
    ...publicationIssueProps,
    ...creativeWorkProps,
    ...thingProps,
  }
}

export const getSchemaOrgJSONLD = ({
                                     url,
                                     title,
                                     image,
                                     description,
                                     logo = "http://google.com/logo.jpg",
                                     comicPage,
                                     issue,
                                     comic,
                                   }) => {
  // there's always a WebSite and WebPage

  let defs = [WebSite, WebPage]

  let comicLD = null
  // if a comic,
  if (comic) {
    comicLD = BookSeries({
      thingProps: {
        description: comic.frontmatter.description || comic.excerpt,
        name: comic.frontmatter.title,
        url: `https://paranoidamerican.com${comic.fields.slug}`,
      },
      creativeWorkProps: {
        ...inLanguageProp,
        abstract: comic.frontmatter.description || comic.excerpt,
        alternativeHeadline: comic.frontmatter.title,
        copyrightHolder: Organization, // Organization
        //copyrightYear: 2012, // Number
        creativeWorkStatus: (comic.frontmatter.published !== false) ? "Published" : "Draft", // Draft | Published
        //editor: Person({ name: "John Doe" }), // Person
        ...inLanguageProp,
        isAccessibleForFree: true,
        ...genreProp,
        publisher: Organization,
      },
      issn: "",
    })
  }

  // if an issue,
  let issueLD
  if (issue) {
    // TODO: add ComicStory either in issue or in array?

    let writers = (_.filter(issue.frontmatter.credits, (o) => {
      return o.role.includes("writing")
    }))

    let mainWriter = (_.find(issue.frontmatter.credits, (o) => {
      return o.role.includes("writing")
    }))

    let mainArtist = (_.find(issue.frontmatter.credits, (o) => {
      return o.role.includes("artwork")
    }))

    let mainColorist = (_.find(issue.frontmatter.credits, (o) => {
      return o.role.includes("colors")
    })) || mainArtist

    let mainInker = (_.find(issue.frontmatter.credits, (o) => {
      return o.role.includes("inks")
    })) || mainArtist

    let mainLetterer = (_.find(issue.frontmatter.credits, (o) => {
      return o.role.includes("letters")
    })) || mainArtist

    let mainPenciler = (_.find(issue.frontmatter.credits, (o) => {
      return o.role.includes("pencils")
    })) || mainArtist

    issueLD = ComicIssue({
      thingProps: {
        description: issue.frontmatter.description,
        name: issue.frontmatter.title,
        url: `https://paranoidamerican.com${issue.fields.slug}`,
      },
      publicationIssueProps: {
        issueNumber: issue.frontmatter.issueNumber,
        pageEnd: issue.frontmatter.pages,
        pageStart: 1,
      },
      comicPersons: {
        artist: mainArtist && Person({
          name: mainArtist.name.name,
          alternateName: mainArtist.name.alternateName,
          sameAs: mainArtist.name.sameAs,
          description: mainArtist.name.description,
          image: `https://paranoidamerican.com${mainArtist.name.image.childImageSharp.fluid.src}`,
        }),
        colorist: mainColorist && Person({
          name: mainColorist.name.name,
          alternateName: mainColorist.name.alternateName,
          sameAs: mainColorist.name.sameAs,
          description: mainColorist.name.description,
          image: `https://paranoidamerican.com${mainColorist.name.image.childImageSharp.fluid.src}`,
        }),
        inker: mainInker && Person({
          name: mainInker.name.name,
          alternateName: mainInker.name.alternateName,
          sameAs: mainInker.name.sameAs,
          description: mainInker.name.description,
          image: `https://paranoidamerican.com${mainInker.name.image.childImageSharp.fluid.src}`,
        }),
        letterer: mainLetterer && Person({
          name: mainLetterer.name.name,
          alternateName: mainLetterer.name.alternateName,
          sameAs: mainLetterer.name.sameAs,
          description: mainLetterer.name.description,
          image: `https://paranoidamerican.com${mainLetterer.name.image.childImageSharp.fluid.src}`,
        }),
        penciler: mainPenciler && Person({
          name: mainPenciler.name.name,
          alternateName: mainPenciler.name.alternateName,
          sameAs: mainPenciler.name.sameAs,
          description: mainPenciler.name.description,
          image: `https://paranoidamerican.com${mainPenciler.name.image.childImageSharp.fluid.src}`,
        }),
        // TODO: array?
        author: mainWriter && Person({
          name: mainWriter.name.name,
          alternateName: mainWriter.name.alternateName,
          sameAs: mainWriter.name.sameAs,
          description: mainWriter.name.description,
          image: `https://paranoidamerican.com${mainWriter.name.image.childImageSharp.fluid.src}`,
        }),
        datePublished: issue.frontmatter.date, // Date
      },
    })
  }

  // if a page
  let pageLD
  if (comicPage) {
    pageLD = {
      ...contextProp,
      "@type": "VisualArtwork",
      description: `${comic.frontmatter.title} - ${issue.frontmatter.title} - page ${comicPage.page}`,
      name: comic.frontmatter.title,
      url: `https://paranoidamerican.com${comic.fields.slug}`,
      position: comicPage.page,
      ...inLanguageProp,
      abstract: comic.frontmatter.description || comic.excerpt,
      alternativeHeadline: comic.frontmatter.title,
      copyrightHolder: Organization, // Organization
      //copyrightYear: 2012, // Number
      creativeWorkStatus: (comic.frontmatter.published !== false) ? "Published" : "Draft", // Draft | Published
      editor: Person({ name: "John Doe" }), // Person
      ...inLanguageProp,
      isAccessibleForFree: true,
      ...genreProp,
      publisher: Organization,
      issn: "",
    }
  }

  if (comicLD) {
    if (issueLD) {
      comicLD.hasPart = [issueLD]
      if (pageLD) {
        issueLD.hasPart = [pageLD]
      }
    }
    defs.push(comicLD)
  }

  return defs
}

export default getSchemaOrgJSONLD