import React from "react"
import { css } from "@emotion/core"

const TapeLabelHeader = ({ children, small, large }) => {

  return (
    (large && <h2
      css={css`
        color:white;                        
        font-family: Special Elite, serif;
        font-weight:300;
        padding:0;
        text-transform: uppercase;
        transition: font-size 0.2s;
        margin: 0px;

        span {
          background-color: #101010;
          display:inline;
          box-decoration-break: clone; 
          font-size: 18px;
          padding: 6px 6px 3px 6px;
        }
        
        @media screen and (min-width: 900px) {
          span {
            padding: 6px 6px 3px 6px;
            font-size: 24px;
          }
        }
        @media screen and (min-width: 960px) {
          span {
            padding: 6px 6px 3px 6px;
            font-size: 24px;
          }
        }
        @media screen and (min-width: 1200px) {
          span {
            padding: 6px 6px 3px 6px;
            font-size: 36px;
          }
        }
`
      }
    >
      <span>{children}</span>
    </h2>)
    || (small && <h2
      css={css`
        color:white;                        
        font-family: Special Elite, serif;
        font-weight:300;
        padding:0;
        text-transform: uppercase;
        transition: font-size 0.2s;
        line-height: 0.6em;
        margin: 0;

        span {
          background-color: #101010;
          display:inline;
          box-decoration-break: clone; 
          font-size: 16px;
          padding: 6px 6px 3px 6px;
        }

        @media screen and (min-width: 1200px) {
          span {
            padding: 6px 6px 3px 6px;
            font-size: 18px;
          }
        }
`
      }
    >
      <span>{children}</span>
    </h2>)
    ||
    <h2
      css={css`

        color: white;                        
        font-family: Special Elite, serif;
        font-weight:300;
        padding:0;
        text-transform: uppercase;
        transition: font-size 0.2s;
        line-height: 0.4em;
        margin: 20px 0 0;

        span {
          background-color: #101010;
          display:inline;
          box-decoration-break: clone; 
          font-size: 18px;
          padding: 8px 8px 4px 8px;
        }
        
        @media screen and (min-width: 900px) {
          line-height: 0.6em;
          span {
            font-size: 22px;
          }
        }
        @media screen and (min-width: 1200px) {
          line-height: 1em;
          span {
            font-size: 24px;
          }
        }
`
      }
    >
      <span>{children}</span>
    </h2>
  )
}

export default TapeLabelHeader
