import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { css } from "@emotion/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faLinkedin, faTumblr, faTwitter } from "@fortawesome/free-brands-svg-icons"

export const SocialMedia = ({style}) => {
  return (
    <div css={style}>
      <OutboundLink target={"_blank"} href={"https://www.instagram.com/paranoidamerican/"}><FontAwesomeIcon
        fixedWidth icon={faInstagram} title={"instagram"}/></OutboundLink>
      <OutboundLink target={"_blank"} href={"https://twitter.com/paranoidamerica"}><FontAwesomeIcon
        fixedWidth
        icon={faTwitter}
        title={"twitter"}/></OutboundLink>
      <OutboundLink target={"_blank"} href={"https://www.facebook.com/ParanoidAmerican/"}><FontAwesomeIcon
        fixedWidth icon={faFacebook} title={"facebook"}/></OutboundLink>
      <OutboundLink target={"_blank"} href={"https://paranoidamerican.tumblr.com/"}><FontAwesomeIcon
        fixedWidth icon={faTumblr} title={"tumblr"}/></OutboundLink>
      <OutboundLink target={"_blank"} href={"https://www.linkedin.com/company/paranoid-american-inc-/"}><FontAwesomeIcon
        fixedWidth icon={faLinkedin} title={"LinkedIn"}/></OutboundLink>
    </div>
  )
}