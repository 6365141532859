import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { SocialMedia } from "./social-media"

const Footer = ({ location, children }) => (
  <footer css={css`
    font-family: Bitter, serif;
    background: #101010;
    color: white;
    padding: 10px 0;
  
    > div {
      margin: 0 auto;
      max-width: 1200px;
      padding:0 20px;
      display: flex;
      flex-direction:row;
      flex-wrap:nowrap;
      justify-content:space-between;
      
      @media screen and (max-width: 1100px) {
        flex-wrap:wrap;
      }
    }
  `}>

    <div>

      <div css={css`min-width:300px;`}>
        <Link to={"/"} title={'home'}>
          &copy; {new Date().getFullYear()} Paranoid American, Inc.
        </Link>
      </div>

      <SocialMedia style={css`
          margin-left: auto;
          font-size:24px;
          a {
            border-bottom: none;
            padding: 3px;
          }
          
          @media screen and (min-width: 1100px) {
            font-size:30px;
          }
      `} />

      <div css={css`
        
        margin-left: auto;
        
        > div:not(:first-of-type) {
          margin-top:10px;
        }
        
        > div {
          text-align: end;
          
          a:not(:first-of-type) {
            display: inline-block;
            padding-left: 20px;
          }
        }
      `}>
        <div css={css`
           a {
              font-size:18px;
              &.active { color: white; }
           }
        `}>
          <Link to={"/comics"} title={'comics'} className={
            ["/time-samplers/",
              "/nasa/",
              "/fiction-faction/",
              "/black-mass-pizza/",
              "/tellamind/",
              "/lees-demons/",
              "/secret-mystery-school/",
              "/power-to-the-sheeple/",
              "/connect-the-dots/",
              "/paranoid-pamphlets/",
              "/paranoid-american-history/",
              "/comics/",
              "/comics"].includes(
              location &&
              location.pathname.match(/^\/[^/]*\//)
                ? location.pathname.match(/^\/[^/]*\//)[0]
                : location && location.pathname) ? "active" : ""}>Comics</Link>
          <Link
            to={"/research"}
            title={'research'}
            className={
              ["/research/", "/research"].includes(
                location &&
                location.pathname.match(/^\/[^/]*\//)
                  ? location.pathname.match(/^\/[^/]*\//)[0]
                  : location && location.pathname) ? "active" : ""}
          >Research</Link>
          <Link
            title={'blog'}
            to={"/blog"}
            className={
              ["/blog/", "/blog"].includes(
                location &&
                location.pathname.match(/^\/[^/]*\//)
                  ? location.pathname.match(/^\/[^/]*\//)[0]
                  : location && location.pathname) ? "active" : ""}
          >Blog</Link>
          <Link to={"/about"}
                className={location && location.pathname === "/about" ? "active" : ""}>About</Link>
          <Link
            to={"/shop"}
            title={'shop'}
            className={
              ["/shop/", "/shop"].includes(
                location &&
                location.pathname.match(/^\/[^/]*\//)
                  ? location.pathname.match(/^\/[^/]*\//)[0]
                  : location && location.pathname) ? "active" : ""}
          >Shop</Link>
        </div>

        <div css={css`
            a {
              font-size:16px;
            }
        `}>
          <Link to={"/about#support"} title={'support us'}>Support Us</Link>
          <Link to={"/privacy-policy"} title={'privacy policy'}>Privacy Policy</Link>
          <Link to={"/about#contact"} title={'contact'}>Contact</Link>
        </div>

      </div>
    </div>
  </footer>
)

export default Footer