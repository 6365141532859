import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPi } from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import {css} from '@emotion/core'

export const Pi = () => {
 return (
   <div css={css`position: relative;`}>
   <div css={css`
      	width:100%;
	text-align: right;
	padding:0 10px;
	font-size:12px;
	position: absolute;
	top:-24px;
    `}
   onClick={(event) => {
     if (event.ctrlKey && event.shiftKey) {
       event.preventDefault()
       window.open('https://www.youtube.com/watch?v=pXPXMxsXT28')
     }
   }}
   ><FontAwesomeIcon icon={faPi}/></div></div>
 )
}