import React from "react"
import { css } from "@emotion/core"
import GatsbyImage from "gatsby-image"
import {Link} from 'gatsby'

const ContentBox = ({ children, fluid, link }) => (
  <div
    className={"drop-shadow clearfix"}
    css={css`
      background-color: white;
      padding: 20px;
      
      a {
        &:hover{ color: black; }
      }
      
      p {
      a {
        color: #101010;
        border-bottom:2px dashed #101010;
        
        
          &:hover {
            background-color: #101010;
            color: white;
         }
        }
      }
      
      h1 {
        font-size: 24px;
        @media screen and (min-width: 480px) {
          font-size: 32px;
        }
        @media screen and (min-width: 740px) {
          font-size: 36px;
        }
        @media screen and (min-width: 960px) {
          font-size: 48px;
        }
      }
      
      h2 {
        font-size: 24px;
        @media screen and (min-width: 480px) {
          font-size: 28px;
        }
        @media screen and (min-width: 740px) {
          font-size: 34px;
        }
        @media screen and (min-width: 960px) {
          font-size: 42px;
        }
      }
      
      h3 {
        font-size: 20px;
        @media screen and (min-width: 480px) {
          font-size: 24px;
        }
        @media screen and (min-width: 740px) {
          font-size: 32px;
        }
        @media screen and (min-width: 960px) {
          font-size: 36px;
        }
      }
      
      h4 {
        font-size: 18px;
        @media screen and (min-width: 480px) {
          font-size: 20px;
        }
        @media screen and (min-width: 740px) {
          font-size: 28px;
        }
        @media screen and (min-width: 960px) {
          font-size: 32px;
        }
      }
      
      h5 {
        font-size: 16px;
        @media screen and (min-width: 480px) {
          font-size: 18px;
        }
        @media screen and (min-width: 740px) {
          font-size: 24px;
        }
        @media screen and (min-width: 960px) {
          font-size: 30px;
        }
      }
      
      margin: 0 20px 30px;
      @media screen and (min-width: 1300px) {
        margin: 0 0 30px;
      }`
    }>
    {link && fluid && <Link to={link} title={'image'}>
      <GatsbyImage fluid={fluid} css={css`width:100px; float:left; margin:0 10px 0 0`} alt={link}/>
    </Link>
    }
    {!link && fluid &&
      <GatsbyImage fluid={fluid} css={css`width:100px; float:left; margin:0 10px 0 0`} alt={link}/>
    }
    {children}
  </div>
)

export default ContentBox