import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
        query SiteMetaData {
            site {
                siteMetadata {
                    title
                    subtitle
                    description
                    keywords
                    author
                }
            }
        }
    `
  )
  return site
}