/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { getComicPageJSONLD, getSchemaOrgJSONLD } from "../jsonld/jsonld"

function SEO({ description, lang, meta, title, keywords, ogImageUrl = "/logo.png", ogImageAlt = "image", url = "https://paranoidamerican.com", comic, issue, page }) {

  let jsonld = getSchemaOrgJSONLD({
    url,
    title,
    description,
    image: ogImageUrl,
    comic,
    issue,
    comicPage: page
  })

  const site = useSiteMetadata()
  keywords = (keywords.length && keywords.length > 0) ? keywords : site.siteMetadata.keywords
  const metaDescription = description || site.siteMetadata.description

  // if title wasn't passed, just default to site title
  title = title || `${site.siteMetadata.title}`
  // if title is same as site title, no template needed
  const titleTemplate = (title === site.siteMetadata.title) ? `%s - ${site.siteMetadata.subtitle}` : `%s | ${site.siteMetadata.title} - ${site.siteMetadata.subtitle}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `viewport`,
          content: `width=-device-width,initial-scale=1.0`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `image`,
          content: `https://paranoidamerican.com${ogImageUrl}`,
        },
        {
          property: `image:alt`,
          content: ogImageAlt,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://paranoidamerican.com${ogImageUrl}`,
        },
        {
          property: `og:image:alt`,
          content: ogImageAlt,
        },
        {
          property: `twitter:image`,
          content: `https://paranoidamerican.com${ogImageUrl}`,
        },
        {
          property: `twitter:image:alt`,
          content: ogImageAlt,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `ParanoidAmerica`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          // handle secure image
          ogImageUrl && ogImageUrl.indexOf("https") > -1
            ? [
              {
                property: "twitter:image:secure_url",
                content: ogImageUrl,
              },
              {
                property: "og:image:secure_url",
                content: ogImageUrl,
              },
            ] : [],
        )
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            } : [],
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(jsonld)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO
