import React from "react"
import Header from "./header"
import Footer from "./footer"
import Headroom from "react-headroom"
import Color from "color"
import {css} from '@emotion/core'
import {useStaticQuery, graphql} from "gatsby"
import { Pi } from "./pi"

const Layout = ({bg = true,
                className,
                location,
                svg,
                color = "#686868",
                children}) => {

  const { file } = useStaticQuery(
    graphql`
        query {
            file(relativePath: { eq: "svg1.svg" }) {
                publicURL
            }
        }
    `
  )

  svg = svg || file.publicURL

    let colorDefault, colorLight, colorDark
    colorDefault = color
    //colorActive = Color(colorDefault).saturate(0.3).hex()
    colorLight = Color(colorDefault).lighten(0.3).hex()
    colorDark = Color(colorDefault).darken(0.3).hex()

    return (
      bg ? <div
          className={`${className} layout-bg`}
          css={css`
              background: url(${svg}), radial-gradient(ellipse at center top, ${colorLight} 0%, ${colorLight} 40%, ${colorDark} 100%);
              background-size: cover;
          `}>
          <Headroom style={{ zIndex: 999 }}>
            <Header
              color={colorLight}
              location={location}
            />
          </Headroom>
          <div className={'layout-main-wrapper'}>
            <div className={'layout-main'}>{children}</div>
          </div>
          <Pi/>
          <Footer location={location}/>
        </div>
        :
        <div className={`${className} layout-bg`}>
          <Headroom style={{ zIndex: 999 }}>
            <Header/>
          </Headroom>
          <div className={'layout-main'}>{children}</div>
          <Pi/>
          <Footer/>
        </div>
    )
}

export default Layout